import React from 'react';
const Footer = () => {
    return (
        <div className="footer">
        <div className="copyright">
            <p>Copyright © ngaze labs</p>
        </div>
    </div>
    );
}

export default Footer;